<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                   <ion-button @click="$router.go(-1)">
                        <ion-img :src="require('@/assets/Icons/arrow-back-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
                <ion-title><h1 class="text-base text-gray-400 text-center font-semibold">Swap History</h1></ion-title>
                <ion-buttons slot="end">
                    <ion-button router-link="/home/settings">
                        <ion-img :src="require('@/assets/Icons/swap-reject-circle-solid-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <div class="grid grid-cols-1 gap-1 pt-4">
                <ion-card
                    v-for="room in rooms"
                    :router-link="`/home/settings/swap-history/rooms/${ room.id }`"
                    :key="room.id"
                    class="h-40 rounded-xl relative my-3"
                >
                    <ion-img :src="room.image" :alt="room.title" class="object-cover absolute z-0 backdrop-filter backdrop-grayscale"/>
                    <div class="relative flex flex-col flex-wrap h-full bg-gradient-to-t from-gray-900 bg-opacity-60">
                        <div class="mt-auto px-4 flex flex-col mb-5">
                            <ion-label class="text-white text-2xl font-bold">{{ room.title }}</ion-label>
                            <ion-label class="text-white text-md">Swap History</ion-label>
                        </div>
                    </div>
                </ion-card>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonCard, IonButtons, modalController, IonImg, IonLabel } from '@ionic/vue';
import {useRouter} from 'vue-router';
import TutorialModal from '@/components/TutorialModal.vue';
export default {
    name: 'ExploreContainer',
    components: { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonCard, IonButtons, IonImg, IonLabel },
    data() {
        return {
            user: [],
            rooms: []
        }
    },
    setup() {
        const openModal = async (id, type) => {
        const modal = await modalController.create({
            component: TutorialModal, //Modal is name of the component to render inside ionic modal
            componentProps: {
                id: id,
                type: type
            },
            swipeToClose: true,
            backdropDismiss: true,
        });
            return modal.present();
        };
        const router = useRouter();
        return {
            router,
            openModal
        };
    },
    async ionViewWillEnter() {
        console.log('Home page did enter');
        this.rooms = await this.$store.getters.rooms();
    },

}
</script>

<style scoped>

</style>
